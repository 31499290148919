//Colors
$blue: #70a2cd;
$blue-dark: #002e40;
$blue-light: #dfe8ee;
$red: #a13f47;
$red1: #d0021b;
$yellow: #fec11e;
$green: #3fa144;
$gray: #b2b2b2;
$gray1: #7f7f7f;
$gray2: #7e7e7e;
$gray3: #f4f8fb;
$green-contrast: rgb(63, 161, 68);
$green-faded: rgba(61, 161, 112, 0.9);

  // Headers
$headings: (
  'sizes': (
          'h1-font-size': 48px,
          'h2-font-size': 24px,
          'h3-font-size': 16px,
          'h4-font-size': 21px,
          'h5-font-size': 17px,
          'h6-font-size': 15px
  ),
  'fonts': (
          'headings-font-family': unquote("'Playfair Display', serif"),
          'headings-color': black
  )
);

// Body
$base: (
  'base-font-sans-serif': unquote("'acumin-pro', Arial, 'Helvetica Neue', Helvetica, sans-serif"),
  'base-font-serif': unquote("'Playfair Display', serif"),
  'base-text-color': $gray1,
  'base-background-color': #fff,
  'base-link-color': black,
  'base-font-size': 18px,
  'base-line-height': 34px,
  'base-font-family': unquote("'acumin-pro', Arial, 'Helvetica Neue', Helvetica, sans-serif"),
  'vertical-rhythm': '(map-get($base, base-line-height) * 1em)',
  'base-min-width': 320px
);

// Forms
$forms: (
  'form-element-padding': unquote("0.4em 0.7em"),
  'form-element-border-color': #999,
  'form-element-focus-border-color': #000,
  'placeholder-color': #999
);

// Breakpoints for include media
$breakpoints: (
  'phone': 480px,
  'tablet': 768px,
  'desktop': 992px,
  'widescreen': 1441px
);

// Keywords
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);
