@import url("https://use.typekit.net/ejv0qwn.css");
body {
    margin: 0;
}
.splash-page {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    position: relative;
    p {
        font-family: 'acumin-pro';
    }
    h1 {
        font-family: "Playfair Display" !important;

    }
    .step-one {
        background: linear-gradient(180deg, #fff 0%, #fff 10%, #002e40 20%, #70a2cd 100%);
        background-position: 0% 100%;
        background-size: 100% 2500%;
        height: 100vh;
        width: 100vw;
        text-align: center;
        display: flex;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        transition: opacity .6s, background 1.4s;
        animation: gradientAnimation 1.8s cubic-bezier(.34, .11, .19, .89);
        @keyframes gradientAnimation {
            0% {
                background-position: 0% 0%
            }
            100% {
                background-position: 0% 100%
            }
        }
        @keyframes gradientAnimationOut {
            0% {
                background-position: 0% 100%
            }
            100% {
                background-position: 0% 0%
            }
        }
        .logo {
            margin: 0 auto 40px;
            width: 100%;
            @include media('<widescreen') {
                margin-bottom: 50px;
            }
            @include media('<desktop') {
                margin-bottom: 30px;
            }
            .svg-box {
                overflow: hidden;
                margin: 0 auto;
                width: 0;
                svg {
                    width: 225px;
                    @include media('<widescreen') {
                        width: 185px;
                    }
                    @include media('<desktop') {
                        width: 135px;
                    }
                }
            }
        }
        .content {
            width: 100%;
            opacity: 0;
            position: relative;
            margin: 0 auto;
            overflow: hidden;
            h1 {
                margin: 0 auto;
                min-width: 980px;
                max-width: 980px;
                font-size: 62px;
                line-height: 92px;
                margin-bottom: 62px;
                position: relative;
                @include media('<widescreen') {
                    font-size: 48px;
                    max-width: 700px;
                    min-width: 700px;
                    line-height: 68px;
                }
                @include media('<desktop') {
                    font-size: 36px;
                    line-height: 48px;
                    max-width: 600px;
                    min-width: 600px;
                }
                @include media('<tablet') {
                    max-width: 100%;
                    min-width: 0;
                    padding: 0 20px;
                }
                &:before {
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 2px;
                    background: white;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -30px;
                    transition: width .678s cubic-bezier(.62, .24, .19, .89);
                    @include media('<tablet') {
                        display: none;
                    }
                }
            }
            &.animate-line {
                h1 {
                    &:before {
                        width: 60px;
                    }
                }
            }
        }
        .footer {
            width: 100%;
            opacity: 0;
            border: none;
            transition: opacity .8s;
            p {
                margin: 0 auto;
                max-width: 780px;
                font-size: 24px;
                line-height: 38px;
                font-weight: 300;
                font-family: acumin-pro;
                @include media('<widescreen') {
                    font-size: 48px;
                    max-width: 600px;
                    font-size: 18px;
                    line-height: 30px;
                }
                @include media('<desktop') {
                    max-width: 480px;
                    min-width: 480px;
                }
                @include media('<tablet') {
                    display: none;
                }
                span {
                    font-family: acumin-pro;
                    font-weight: 100;
                }
            }
        }
        h1, p {
            color: white;
        }
    }
    .step-two {
        display: none;
        position: relative;
        width: 100vw;
        height: 100vh;
        .logo {
            position: absolute;
            top: 80px;
            left: 50%;
            transform: translateX(-50%);
            margin: 0 auto 40px;
            width: 100%;
            z-index: 13;
            opacity: 0;
            @include media('<desktop') {
                position: relative;
                top: 0;
                margin-bottom: 0;
                height: 102px;
                background: #002e40;
                opacity: 0;
            }
            .svg-box {
                overflow: hidden;
                margin: 0 auto;
                width: 225px !important;
                @include media('<widescreen') {
                    width: 185px !important;
                }
                svg {
                    width: 225px;
                    @include media('<widescreen') {
                        width: 185px;
                    }
                    @include media('<desktop') {
                        margin-top: 22px;
                    }
                }
            }
        }

        .links {
            position: relative;
            z-index: 13;
            width: 100%;
            height: 100vh;
            @include media('<desktop') {
                position: absolute;
                height: calc(100vh - 162px);
            }
            a {
                cursor: none;
                h1 {
                    font-size: 78px;
                    line-height: 116px;
                    letter-spacing: 0;
                    color: white;
                    @include media('<widescreen') {
                        font-size: 48px;
                    }
                    @include media('<desktop') {
                        font-size: 54px;
                        line-height: 1;
                        margin: 0;
                    }
                    @include media('<tablet') {
                        font-size: 31px;
                    }
                }
            }
            @keyframes loadedAnimation {
                0% {
                    transform: rotate(45deg)
                }
                35% {
                    transform: rotate(45deg)
                }
                55% {
                    transform: rotate(225deg)
                }
                100% {
                    transform: rotate(135deg)
                }
            }
            @keyframes loadedAnimationRight {
                0% {
                    transform: rotate(45deg)
                }
                35% {
                    transform: rotate(45deg)
                }
                55% {
                    transform: rotate(225deg)
                }
                100% {
                    transform: rotate(135deg)
                }
            }
            .for-investors {
                text-align: center;
                position: absolute;
                top: 50%;
                left: 25%;
                transform: translateX(-50%) translateY(-50%);
                opacity: 0;
                transition: opacity 1.5s;
                @include media('<widescreen') {
                    left: 27%;
                }
                @include media('<desktop') {
                    left: 50%;
                    top: 25%;
                    width: max-content;
                    transform: translateX(-50%) translateY(-75%);
                }
                &:before, &:after {
                    position: absolute;
                    left: -90px;
                    top: 46px;
                    content: ' ';
                    height: 33px;
                    width: 2px;
                    background-color: white;
                    @include media('<widescreen') {
                        left: -69px;
                        height: 24px;
                        top: 52px;
                    }
                    @include media('<desktop') {
                        left: -55px;
                        top: 19px;
                    }
                    @include media('<tablet') {
                        height: 16px;
                        left: -29px;
                        top: 11px;
                        width: 2px;
                    }
                }
                &:before {
                    transform: rotate(0deg);
                    transition: transform .6s;
                }
                &:after {
                    transform: rotate(90deg);
                    transition: transform .6s;
                }
                &:hover {
                    &:before {
                        transform: rotate(0) scale(1.1);
                    }
                    &:after {
                        transform: rotate(90deg) scale(1.1);
                    }
                    h1 {
                        &:before {
                            transform: rotate(45deg) scale(1.1);
                        }
                    }
                }
                h1 {
                    &:before {
                        content: '';
                        position: absolute;
                        top: 21px;
                        left: -128px;
                        height: 80px;
                        width: 80px;
                        transform: rotate(45deg);
                        border: 2px solid white;
                        @include media('<widescreen') {
                            left: -96px;
                            top: 37px;
                            height: 50px;
                            width: 50px;
                        }
                        @include media('<desktop') {
                            top: 4px;
                            left: -80px;
                        }
                        @include media('<tablet') {
                            height: 30px;
                            width: 30px;
                            top: 2px;
                            left: -45px;
                        }
                    }
                    &:hover {
                        &:before {
                            transform: rotate(45deg) scale(1.2);
                        }
                    }
                }
                &.loaded {
                    opacity: 1;
                    cursor: pointer;
                    h1 {
                        letter-spacing: 0;
                        &:before {
                            transition: transform .6s;
                        }
                    }
                }
            }
            .for-residents {
                text-align: center;
                position: absolute;
                top: 50%;
                right: 25%;
                transform: translateX(50%) translateY(-50%);
                opacity: 0;
                transition: opacity 1.5s .5s;
                @include media('<widescreen') {
                    right: 27%;
                }
                @include media('<desktop') {
                    top: auto;
                    right: auto;
                    left: 50%;
                    width: max-content;
                    bottom: 25%;
                    transform: translateX(-50%) translateY(75%);
                }
                &:before, &:after {
                    position: absolute;
                    right: -85px;
                    top: 46px;
                    content: ' ';
                    height: 33px;
                    width: 2px;
                    background-color: white;
                    @include media('<widescreen') {
                        top: 52px;
                        right: -69px;
                        height: 24px;
                    }
                    @include media('<desktop') {
                        right: -53px;
                        top: 19px;
                    }
                    @include media('<tablet') {
                        height: 16px;
                        right: -29px;
                        top: 11px;
                        width: 2px;
                    }
                }
                &:before {
                    transform: rotate(0deg);
                    transition: transform .6s;
                }
                &:after {
                    transform: rotate(90deg);
                    transition: transform .6s;
                }
                &:hover {
                    &:before {
                        transform: rotate(0deg) scale(1.1);
                    }
                    &:after {
                        transform: rotate(90deg) scale(1.1);
                    }
                    h1 {
                        &:before {
                            transform: rotate(45deg) scale(1.1);
                        }
                    }
                }
                h1 {
                    transition: letter-spacing 2s 4s !important;
                    &:before {
                        content: '';
                        position: absolute;
                        top: 21px;
                        right: -128px;
                        height: 80px;
                        width: 80px;
                        transform: rotate(45deg);
                        border: 2px solid white;
                        transition: transform .6s;
                        @include media('<widescreen') {
                            right: -96px;
                            top: 37px;
                            height: 50px;
                            width: 50px;
                        }
                        @include media('<desktop') {
                            top: 4px;
                            right: -80px;
                        }
                        @include media('<tablet') {
                            height: 30px;
                            width: 30px;
                            top: 2px;
                            right: -45px;
                        }
                    }
                    &:hover {
                        &:before {
                            transform: rotate(45deg) scale(1.2);
                        }
                    }
                }
                &.loaded {
                    opacity: 1;
                    cursor: pointer;
                    h1 {
                        &:before {
                            //animation: loadedAnimation 2s .5s;
                        }
                    }
                }
            }
        }

        .left-wrapper {
            position: absolute;
            width: 50%;
            height: 100vh;
            left: -100%;
            z-index: 2;
            overflow: hidden;
            width: 59%;
            height: 100%;
            clip-path: polygon(100% 0%, 70% 100%, 0% 100%, 0% 0%);
            overflow: hidden;
            z-index: 5;
            @include media('<widescreen') {
                width: 67%;
                clip-path: polygon(100% 0%, 50% 100%, 0% 100%, 0% 0%);
            }
            @include media('<desktop') {
                position: relative;
                width: 100%;
                height: calc(50vh - 81px);
                clip-path: none;
            }
            .img-under-the-mask, .swap-image {
                background: url('../images/left-splash-1000w.jpg') center center no-repeat;
                background-color: #935d8c;
                min-width: 50px;
                min-height: 50px;
                background-size: cover;
                position: absolute;
                top: 0;
                right: -100%;
                z-index: 2;
                height: 100%;
                width: 100%;
                transition: right 1.75s cubic-bezier(.32, .16, 0, .97), transform 1s;
                @include media('<desktop') {
                    background-position: 100% 100%;
                }
                &:before {
                    position: absolute;
                    content: '';
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 80%, white 100%);
                    z-index: 12;
                    transition: opacity 1s .5s, right 1s .3s;
                }
                &:after {
                    position: absolute;
                    content: '';
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    bakground: transparent;
                    z-index: 12;
                    opacity: 0;
                    transition: opacity 2s 1s;
                }
                &.no-overlay {
                    &:before {
                        opacity: 0;
                        right: 20%;
                    }
                }
                &.non-hovered {
                    &:after {
                        background: #002e40;
                        opacity: .8;
                        transition: opacity 2s;
                        @include media('<desktop') {
                            background: rgba(0, 0, 0, 0.26);
                        }
                    }
                }
                &.hovered {
                    transform: scale(1.1);
                    &:after {
                        opacity: 0;
                    }
                }
            }
            .swap-image {
                opacity: 0;
                background-size: cover !important;
                background: url('../images/left_image_v2_1000w.jpg') center center no-repeat;
                background-color: #935d8c;
                min-width: 50px;
                min-height: 50px;
            }
        }
        .right-wrapper {
            position: absolute;
            width: 50%;
            height: 100vh;
            right: -100%;
            z-index: 2;
            overflow: hidden;
            width: 59%;
            height: 100%;
            clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 30% 0%);
            overflow: hidden;
            z-index: 5;
            @include media('<widescreen') {
                width: 67%;
                clip-path: polygon(100% 0%, 100% 100%, 0% 100%, 50% 0%);
            }
            @include media('<desktop') {
                position: relative;
                width: 100%;
                height: calc(50vh - 81px);
                clip-path: none;
            }
            .img-under-the-mask, .swap-image {
                background: url('../images/right-splash-1000w.jpg') center center no-repeat;
                background-size: cover;
                position: absolute;
                top: 0;
                right: 100%;
                z-index: 2;
                background-color: #935d8c;
                min-width: 50px;
                min-height: 50px;
                height: 100%;
                width: 100%;
                transition: right 1.75s cubic-bezier(.32, .16, 0, .97), transform 1s;
                @include media('<desktop') {
                    background-position: 100% 100%;
                }

                //half transparent
                &:before {
                    position: absolute;
                    content: '';
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 80%, white 100%);
                    z-index: 12;
                    transition: opacity 1s .5s, left 1s .3s;
                }
                &:after {
                    position: absolute;
                    content: '';
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    bakground: transparent;
                    z-index: 12;
                    transition: opacity 2s 1s;
                    opacity: 0;
                }
                &.no-overlay {
                    &:before {
                        opacity: 0;
                        left: 20%;
                    }
                }
                &.non-hovered {
                    &:after {
                        background: #70a2cd;
                        opacity: .8;
                        transition: opacity 2s;
                        @include media('<desktop') {
                            background: rgba(0, 0, 0, 0.26);
                        }
                    }
                }
                &.hovered {
                    transform: scale(1.1);
                    &:after {
                        opacity: 0;
                    }
                }
            }
            .swap-image {
                opacity: 0;
                background-size: cover !important;
                background: url('../images/right_image_v2_1000w.jpg') center center no-repeat;
                background-color: #935d8c;
                min-width: 50px;
                min-height: 50px;
            }
        }

        .contact-us {
            width: 100%;
            height: 100vh;
            position: absolute;
            @include media('<desktop') {
                height: 60px;
                display: flex;
                bottom: 0;
                z-index: 5;
                background: #032e3f;
                justify-content: center;
                align-items: center;
            }
            span {
                @include media('<desktop') {
                    width: 1px;
                    font-size: 0 !important;
                }
                &:before {
                    @include media('<desktop') {
                        content: '';
                        position: absolute;
                        width: 1px;
                        height: 30px;
                        background: white;
                        bottom: -2px;
                        left: 0px;
                    }
                }
            }
            a, span {
                font-family: acumin-pro;
                font-weight: 100;
                width: auto;
                text-align: center;
                opacity: 0;
                color: white;
                position: absolute;
                bottom: 80px;
                z-index: 13;
                font-size: 18px;
                line-height: 30px;
                letter-spacing: 0.7px;
                text-transform: capitalize;
                transition: letter-spacing .8s, opacity 1s;
                right: 50%;
                padding-left: 0;
                padding-right: 40px;
                left: auto;
                transition: color .3s;
                @include media('<desktop') {
                    bottom: auto;
                    font-size: 16px;;
                    margin: 0;
                    padding: 0;
                    right: auto;
                    opacity: 1;
                    position: relative;
                    margin: 0 10px;
                }
                &:last-child {
                    left: 50%;
                    padding-left: 40px;
                    width: max-content;
                    @include media('<desktop') {
                        padding: 0;
                        left: auto;
                        right: auto;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        width: 1px;
                        height: 30px;
                        background: white;
                        bottom: -2px;
                        left: 5px;
                        @include media('<desktop') {
                            display: none;
                        }
                        &:hover {
                            color: #e0e0e0;
                        }
                    }

                }

            }
        }
    }
}