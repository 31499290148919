@import '../../node_modules/include-media/dist/include-media';
@import 'helpers/map-deep-get';
@import 'components/animate';

/* Variables */
@import 'components/variables';

/* Base modules*/
@import 'components/typography';

/* Pages*/
@import 'pages/splash';