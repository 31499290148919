// Typography

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
.h {
    font-family: map-deep-get($headings, 'fonts', 'headings-font-family');
    font-weight: normal;
    margin: 0 0 0.5em;
    color: map-deep-get($headings, 'fonts', 'headings-color');
}

.big-title {
    h1 {
        font-size: 76px;
        line-height: 90px;
        letter-spacing: 0;
        margin: 0 0 40px;
        @include media('<desktop') {
            font-size: 60px;
            line-height: 80px;
            margin: 0 0 30px;
        }
        @include media('<tablet') {
            font-size: 36px;
            line-height: 48px;
        }
    }
    h2 {
        font: 32px/48px map-deep-get($headings, 'fonts', 'headings-font-family');
        letter-spacing: 0;
        margin: 0 0 14px;
    }
}

h1, .h1 {
    font-size: map-deep-get($headings, 'sizes', 'h1-font-size');
    line-height: 70px;
    margin: 0 0 40px;
    @include media('<desktop') {
        font-size: 36px;
        line-height: 48px;
        margin: 0 0 30px;
    }
}

h2, .h2 {
    font-size: map-deep-get($headings, 'sizes', 'h2-font-size');
    font-family: map-get($base, base-font-sans-serif);
    line-height: 38px;
    margin: 0 0 38px;
    font-weight: 300;
    @include media('<desktop') {
        font-size: 18px;
        line-height: 30px;
    }
}

h3, .h3 {
    font-family: map-get($base, base-font-sans-serif);
    font-size: map-deep-get($headings, 'sizes', 'h3-font-size');
    line-height: 25px;
    margin: 0 0 20px;
    @include media('<widescreen') {
        margin: 0 0 10px;
    }
}

h4, .h4 {
    font-size: map-deep-get($headings, 'sizes', 'h4-font-size');
}

h5, .h5 {
    font-size: map-deep-get($headings, 'sizes', 'h5-font-size');
}

h6, .h6 {
    font-size: map-deep-get($headings, 'sizes', 'h6-font-size');
}

p {
    margin: 0 0 40px;
}

a {
    color: map-get($base, 'base-link-color');
    text-decoration: none;
    transition: color .3s, background .3s;
    &:hover,
    &:focus {
        text-decoration: none;
        color: $gray1;
    }
    &:focus {
        outline: dotted 1px #70a2cd;
        outline-offset: 2px;
    }
}

button {
    &:focus {
        outline: dotted 1px #70a2cd;
        outline-offset: 2px;
    }
}

blockquote{
    background-color: $gray3;
    background-image: url(../images/quote.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    font-size: 18px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.89;
    letter-spacing: 0.7px;
    text-align: center;
    color: #000;
    padding: 80px 100px;
    margin: 0 0 60px;
    @include media('<widescreen') {
        padding: 80px 83px 70px 80px;
    }
    @include media('<tablet') {
        padding: 80px 20px;
    }
    p {
        margin: 0;
    }
}
